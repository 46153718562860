import { Firebase } from '@platform/core';

class TokenError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = 'TokenError';
    this.statusCode = statusCode;
  }
}

export class SegmentAPI {
  constructor() {
    this.urlBase = process.env.BACKEND_URL;
  }

  async getIdToken() {
    const user = Firebase.getCurrentUser();
    const token = await user.getIdToken();
    return token;
  }

  handleError(res) {
    if (res.status >= 400 && res.status <= 500) {
      if (res.status === 401) {
        throw new TokenError(401, 'Unauthorized');
      } else if (res.status === 403) {
        throw new TokenError(403, 'Forbidden');
      } else {
        throw new Error(res.statusText);
      }
    }
  }

  async getResponseData(response) {
    const resp_text = await response.text();
    const contentType = response.headers.get('Content-Type');
    if (
      resp_text &&
      contentType &&
      contentType.indexOf('application/json') !== -1
    ) {
      return JSON.parse(resp_text);
    } else {
      return resp_text;
    }
  }

  async segment(data) {
    const url = `${this.urlBase}/semi-automatic/contour`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    this.handleError(res);
    const resData = await this.getResponseData(res);
    const { mask_contour } = resData;
    return mask_contour;
  }
}
