// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x-container {
    padding: 12px;
}

.x-input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #494949;
    font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: padding-box;
}

.x-input:focus {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
            box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}`, "",{"version":3,"sources":["webpack://./cloud/auth/AuthCode.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd;kBACc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;OACrB,qBAAqB;YAChB,gBAAgB;IACxB,UAAU;IACV,oDAAoD;YAC5C,4CAA4C;AACxD","sourcesContent":[".x-container {\n    padding: 12px;\n}\n\n.x-input {\n    width: 45px;\n    height: 45px;\n    padding: 0;\n    font-size: 24px;\n    text-align: center;\n    margin-right: 12px;\n    text-transform: uppercase;\n    color: #494949;\n    font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,\n        sans-serif;\n    border: 1px solid #d6d6d6;\n    border-radius: 4px;\n    background: #fff;\n    background-clip: padding-box;\n}\n\n.x-input:focus {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n    outline: 0;\n    -webkit-box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);\n            box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
