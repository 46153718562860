import React, { useState } from 'react';
import propTypes from 'prop-types';
import GoogleButton from 'react-google-button';
import AuthCode from 'react-auth-code-input';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
  LinearProgress,
  Grid,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { Firebase } from '@platform/core';
import DevicesTwoToneIcon from '@material-ui/icons/DevicesTwoTone';
import SmartphoneTwoToneIcon from '@material-ui/icons/SmartphoneTwoTone';

import Hero from '../../static/images/HeroTheme.png';
import './AuthCode.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
  },
}));

const BorderLinearProgress = withStyles(theme => ({
  root: { height: 8, borderRadius: 4 },
  colorPrimary: { backgroundColor: theme.palette.grey[300] },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export function Home({ isAuthStateChanged }) {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [step, setStep] = useState(0);
  const [resolver, setResolver] = useState({ hints: [] });
  const [id, setId] = useState('');
  const [hint, setHint] = useState({});
  const [code, setCode] = useState('');

  const handleSetEmail = e => setEmail(e.target.value);
  const handleSetPassword = e => setPassword(e.target.value);
  const emailSignIn = async (email, password) => {
    try {
      setIsProcessing(true);
      await Firebase.signInWithEmailAndPassword(email, password);
    } catch (err) {
      if (err.code === 'auth/multi-factor-auth-required') {
        const resolver = await Firebase.getMultiFactorResolverWithError(err);
        setResolver(resolver);
        setStep(1);
      } else {
        window.alert('Email / Password authentication failed.');
      }
    } finally {
      setIsProcessing(false);
    }
  };
  const resetPassword = async email => {
    try {
      setIsProcessing(true);
      await Firebase.resetPassword(email);
    } catch (e) {
      window.alert('Failed to send password reset email.');
    } finally {
      setIsProcessing(false);
    }
  };
  const googleSignIn = async () => {
    try {
      setIsProcessing(true);
      await Firebase.signInWithGooglePopup();
    } catch (err) {
      if (err.code === 'auth/multi-factor-auth-required') {
        const resolver = await Firebase.getMultiFactorResolverWithError(err);
        setResolver(resolver);
        setStep(1);
      } else {
        window.alert('Email / Password authentication failed.');
      }
    } finally {
      setIsProcessing(false);
    }
  };
  const sendCode = async hint => {
    try {
      setIsProcessing(true);
      if (hint.factorId === 'phone') {
        const id = await Firebase.handlePhoneMultiFactor(resolver);
        setId(id);
        setHint(hint);
        setStep(2);
      }
    } catch (err) {
      window.alert('Failed to send verification code.');
    } finally {
      setIsProcessing(false);
    }
  };
  const verifyCode = async () => {
    try {
      setIsProcessing(true);
      await Firebase.resolvePhoneMultiFactor(resolver, id, code);
      setStep(0);
    } catch (err) {
      window.alert('Failed to verify code.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Panel value={step} index={0}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ minWidth: '400px' }}
                >
                  <img src={Hero} alt="Vysioneer" style={{ width: '100%' }} />
                  {!isAuthStateChanged ? (
                    <div style={{ width: '80%' }}>
                      <BorderLinearProgress />
                    </div>
                  ) : (
                    <Box
                      style={{
                        width: '90%',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        gridGap: '5px',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        fullWidth
                        id="email"
                        type="email"
                        label="Email"
                        margin="dense"
                        variant="outlined"
                        onChange={handleSetEmail}
                      />
                      <TextField
                        fullWidth
                        id="password"
                        type="password"
                        label="Password"
                        margin="dense"
                        variant="outlined"
                        onChange={handleSetPassword}
                        onKeyDown={e =>
                          e.key === 'Enter' && emailSignIn(email, password)
                        }
                      />
                      <Button
                        fullWidth
                        id="login"
                        color="primary"
                        variant="contained"
                        onClick={() => emailSignIn(email, password)}
                        disabled={isProcessing}
                      >
                        Sign In
                      </Button>
                      <Button
                        onClick={() => resetPassword(email)}
                        disabled={isProcessing}
                      >
                        Reset Password
                      </Button>
                      <GoogleButton
                        type="light"
                        style={{
                          width: '100%',
                          margin: '20px 0 0',
                          borderRadius: '5px',
                        }}
                        onClick={googleSignIn}
                      />
                    </Box>
                  )}
                </Box>
              </Panel>
              <Panel value={step} index={1}>
                <Box style={{ marginBottom: '10px' }}>
                  <DevicesTwoToneIcon
                    color="primary"
                    style={{ fontSize: 60 }}
                  />
                  <Typography color="primary" variant="h4">
                    Verification Method
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridGap: '10px',
                  }}
                >
                  {resolver.hints.map(hint => (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => sendCode(hint)}
                      disabled={isProcessing}
                    >
                      {hint.factorId}
                    </Button>
                  ))}
                  <Button
                    color="default"
                    variant="contained"
                    fullWidth
                    onClick={() => setStep(0)}
                  >
                    Use another account
                  </Button>
                </Box>
              </Panel>
              <Panel value={step} index={2}>
                <Box>
                  <SmartphoneTwoToneIcon
                    color="primary"
                    style={{ fontSize: 60 }}
                  />
                  {hint.factorId === 'phone' ? (
                    <>
                      <Typography color="primary" variant="h4">
                        Send Verification Code
                      </Typography>
                      <Typography color="primary" variant="h6">
                        to Phone Number {hint.phoneNumber}
                      </Typography>
                    </>
                  ) : (
                    <Typography>Enter Verification Code</Typography>
                  )}
                </Box>
                <Box style={{ alignItems: 'center' }}>
                  <AuthCode
                    containerClassName="x-container"
                    inputClassName="x-input"
                    allowedCharacters="numeric"
                    onChange={setCode}
                  />
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      color="default"
                      variant="contained"
                      fullWidth
                      onClick={() => setStep(1)}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={verifyCode}
                      disabled={isProcessing}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Panel>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
Home.propTypes = { isAuthStateChanged: propTypes.bool };

const Panel = props => {
  const { children, value, index } = props;
  return value === index && children;
};
