// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-button {
  height: 48px;
  color: var(--default-color);
  float: left;
  text-align: center;
  margin: 0 2px;
  padding: 0 8px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Chrome and Opera */
}
.toolbar-button .toolbar-button-label {
  font-size: 12px;
  font-weight: 500;
}
.toolbar-button .expand-caret {
  width: 8px;
  height: 8px;
  transform: translate(2px, 2px);
}
.toolbar-button svg {
  height: 21px;
  width: 21px;
  margin: 2px;
}
.toolbar-button:hover {
  color: var(--default-color);
  background-color: var(--ui-border-color-dark);
  border-radius: 4px;
}
.toolbar-button:hover.disabled {
  color: var(--default-color);
  background-color: transparent;
  cursor: not-allowed;
}
.toolbar-button.active,
.toolbar-button:active {
  color: var(--primary-background-color);
  background-color: var(--active-color);
  border-radius: 4px;
}
.toolbar-button.disabled {
  opacity: 0.3;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/toolbarButton/toolbar-button.styl"],"names":[],"mappings":"AAAA;EACE,YAAQ;EACR,2BAAO;EACP,WAAO;EACP,kBAAY;EACZ,aAAQ;EACR,cAAS;EACT,eAAQ;EACR,2BAAuB,EAAvB,eAAA;EAA6B,yBAAA,EAAA,WAAA;EAC7B,wBAAqB,EAArB,mBAAA;EAA2B,sBAAA,EAAA,YAAA;EAC3B,qBAAoB,EAAA,2BAApB;EAA0B,iBAAA,EAAA,qBAAA;AAI5B;AAH0B;EACxB,eAAA;EAAuB,gBAAA;AAMzB;AALqB;EAOnB,UAAU;EALV,WAAA;EACE,8BAAA;AAOJ;AACA;EALE,YAAA;EACE,WAAA;EACA,WAAQ;AAOZ;AACA;EALE,2BAAA;EACE,6CAAA;EACA,kBAAA;AAOJ;AACA;EALE,2BAAA;EACE,6BAAA;EACA,mBAAkB;AAOtB;AACA;;EALM,sCAAA;EACA,qCAAA;EACA,kBAAQ;AAQd;AANE;EAAU,YAAA;AASZ","sourcesContent":[".toolbar-button\n  height: 48px;\n  color: var(--default-color);\n  float: left;\n  text-align: center;\n  margin: 0 2px;\n  padding: 0 8px;\n  cursor: pointer;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Chrome and Opera */\n\n  .toolbar-button-label\n    font-size: 12px;\n    font-weight: 500;\n\n  .expand-caret\n    width: 8px;\n    height: 8px;\n    transform: translate(2px, 2px);\n\n  svg\n    height: 21px;\n    width: 21px;\n    margin: 2px;\n\n  &:hover\n    color: var(--default-color);\n    background-color: var(--ui-border-color-dark);\n    border-radius: 4px;\n    &.disabled\n      color: var(--default-color);\n      background-color: transparent;\n      cursor: not-allowed;\n\n  &.active, &:active\n    color: var(--primary-background-color);\n    background-color: var(--active-color);\n    border-radius: 4px;\n\n  &.disabled\n    opacity: 0.3;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
