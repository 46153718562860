import firebase from "firebase/app";

let initialized = false;
export function init(config) {
  if (!initialized) {
    firebase.initializeApp(config);
    initialized = true;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-root',
      { size: 'invisible' }
    );
  }
}
