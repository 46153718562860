import SyncButton from '../../components/SyncButton';
import MPRButton from '../../components/MPRButton';

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
};

const toolbarModule = ({ commandsManager }) => {
  return {
    definitions: [
      {
        id: 'Stack',
        label: 'Stack',
        icon: 'bars',
        buttons: [
          {
            id: 'Scroll',
            label: 'Scroll',
            icon: 'bars',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'StackScroll' },
          },
          {
            id: 'Prev',
            label: 'Prev',
            icon: 'caret-up',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'previousImage',
          },
          {
            id: 'Next',
            label: 'Next',
            icon: 'caret-down',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'nextImage',
          },
          {
            id: 'rotate-cw',
            label: 'CW',
            icon: 'rotate',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'rotateViewportCW',
          },
          {
            id: 'rotate-ccw',
            label: 'CCW',
            icon: 'reset',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'rotateViewportCCW',
          },
          {
            id: 'flip-v',
            label: 'Flip V',
            icon: 'arrows-alt-v',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'flipViewportVertical',
          },
          {
            id: 'flip-h',
            label: 'Flip H',
            icon: 'arrows-alt-h',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'flipViewportHorizontal',
          },
          {
            id: 'download',
            label: 'Download',
            icon: 'create-screen-capture',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'showDownloadViewportModal',
          },
        ],
      },
      {
        id: 'Pan',
        label: 'Pan',
        icon: 'arrows',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Pan' },
      },
      {
        id: 'Zoom',
        label: 'Zoom',
        icon: 'search-plus',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Zoom' },
      },
      {
        id: 'Wwwc',
        label: 'Levels',
        icon: 'level',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Wwwc' },
      },
      {
        id: 'Reset',
        label: 'Reset',
        icon: 'reset',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'resetViewport',
      },
      {
        id: 'SyncScroll',
        label: 'Sync',
        icon: 'link',
        commandsManager,
        CustomComponent: SyncButton,
      },
      {
        id: 'Multiplanar',
        label: 'MPR',
        icon: 'cube',
        commandsManager,
        CustomComponent: MPRButton,
      },
      {
        id: 'Measure',
        label: 'Measure',
        icon: 'measure-target',
        buttons: [
          {
            id: 'Probe',
            label: 'Probe',
            icon: 'dot-circle',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'DragProbe' },
          },
          {
            id: 'Length',
            label: 'Length',
            icon: 'measure-temp',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'Length' },
          },
          {
            id: 'Bidirectional',
            label: 'Bidirectional',
            icon: 'measure-target',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'Bidirectional' },
          },
          {
            id: 'Angle',
            label: 'Angle',
            icon: 'angle-left',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'Angle' },
          },
          {
            id: 'Eraser',
            label: 'Eraser',
            icon: 'eraser-fill',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'Eraser' },
          },
          {
            id: 'Clear',
            label: 'Clear',
            icon: 'trash',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'clearMeasurements',
          },
        ],
      },
    ],
    defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
  };
};

export default toolbarModule;
