/** platform.vysioneer.com */

import getHotkeys from './getHotkeys';
import { getPrefetcher, getDICOMwebUrl } from './getUrlSearchParams';

export default function config() {
  const baseMap = {
    default: 'https://arched-jigsaw-248702.uc.r.appspot.com/healthcare',
    'asia-east1':
      'https://vysioneer-production-platform.de.r.appspot.com/healthcare',
    'asia-northeast1':
      'https://vysioneer-production-platform.de.r.appspot.com/healthcare',
  };
  const urlRoot = getDICOMwebUrl({ baseMap: baseMap });
  return {
    routerBasename: process.env.BASE_URL,
    homepageUrl: process.env.HOMEPAGE_URL,
    redirectUrl: process.env.REDIRECT_URL,
    backendUrl: process.env.BACKEND_URL,
    showPatientList: false,
    readOnly: false,
    enableOnPremiseAPI: false,
    enableSubmitRtstructButton: true,
    enableDownloadRtstructButton: false,
    enableRTExtension: true,
    enableTrackingExtension: false,
    enableLongitudinalExtension: true,
    enableLocal: true,
    firebaseConfig: {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      projectId: process.env.FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
      appId: process.env.FIREBASE_APP_ID,
      autoLogout: true,
      enableSubmitLogs: false,
      enableUpdateEndpoints: false,
    },
    dicomWebServer: 'healthcareApi',
    dicomWebProxy: {
      projectId: 'arched-jigsaw-248702',
      endpoint: baseMap,
    },
    servers: {
      dicomWeb: [
        {
          name: 'Google Healthcare API',
          imageRendering: 'wadors',
          thumbnailRendering: 'wadors',
          type: 'dicomWeb',
          active: true,
          wadoUriRoot: urlRoot,
          qidoRoot: urlRoot,
          wadoRoot: urlRoot,
          supportsFuzzyMatching: false,
          qidoSupportsIncludeField: false,
        },
      ],
    },
    studyPrefetcher: getPrefetcher({
      enabled: true,
      displaySetCount: 3,
      maxNumPrefetchRequests: 30,
    }),
    maxConcurrentMetadataRequests: 5,
    maxCacheSizeInBytes: 2 * 1024 * 1024 * 1024,
    hotkeys: getHotkeys(),
  };
}
